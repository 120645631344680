/* wapParks.css */
.users__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.users__heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.users__list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
}

.users__list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  transition: box-shadow 0.3s ease-in-out;
}

.users__list-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.users__list-item-button {
  padding: 10px 15px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.4s;
}

.users__list-item-button:hover {
  background-color: white; 
  color: #4CAF50; 
  border: 2px solid #4CAF50;
}

/* Styles for the sliding pane content */
.park-details-form {
  padding: 20px;
}

.park-details-section {
  margin-bottom: 20px;
}

.park-details-section h3 {
  margin-bottom: 10px;
  color: #555;
}

.new-apiid-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  
  
}

.gate-time-section {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 5px;
}
